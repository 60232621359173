<template>
    <div class="ad_cell_inline_targetcp">
      <div v-if="value.row.target_cpa">
        <span v-if="value.row.target_cpa_editing === undefined">
          {{ formatedMoney(value.row.target_cpa) }}
          <i
            class="el-icon-edit-outline"
            @click="beforeEditBuget(true)"
          ></i>
        </span>
        <span v-if="value.row.target_cpa_editing !== undefined">
          <el-input
            size="small"
            v-model="value.row.target_cpa_editing"
            @input="refreshTableRow"
            v-cell-focus
            @blur="cancelEditBuget"
            @change="editBudget(true)"
          >
          </el-input>
        </span>
        <br /><span class="color9">目标出价</span>
      </div>
    </div>
</template>

<script>
import { cmpaignBudget } from "@/api/google/account.js";
import { mapGetters } from "vuex";
export default {
    name: "AdCellInlineEditTargetCp",
    components:{},
    directives: {
      "cell-focus": {
        bind(el, binding) {
          // el.focus();
          setTimeout(() => {
            el.children[0].focus();
          }, 200);
        },
      },
    },
    props:{
      value: {
        type: Object,
        default:()=>{}
      },
      accountId: {
        type: [String, Array],
        default: "",
      },
    },
    data(){
        return {
          
        }
    },
    computed: {
      ...mapGetters(["toFormatedMoney"]),
    },
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
      formatedMoney(v) {
        if(!(v))return "";
        return "$" + (parseFloat(v) ).toFixed(2);
      },
      beforeEditBuget(isDaily) {
        let scope = this.value;
        let val = parseFloat(scope.row.target_cpa) ;
        this.$set(scope.row, "target_cpa_editing", val);
        console.log(scope.row.target_cpa_editing);
        this.$forceUpdate();
        this.refreshTableRow();
      },
      refreshTableRow() {
        let scope = this.value;
        this.$emit("scope-row-changed", scope);
      },
      refreshTable() {
        this.$emit("get-list");
      },
      cancelEditBuget() {
        let scope = this.value;
        let result = scope.row.target_cpa_editing;//this.$store.getters.toBaseMoney(scope.row.target_cpa_editing);
        if (
          result === parseInt(scope.row.target_cpa)
        ) {
          scope.row.target_cpa_editing = undefined;
          this.refreshTableRow();
        }
      },
      editBudget(isDaily) {
      let scope = this.value;
      let row = scope.row;
      console.log(row);
      let targetVal = scope.row.target_cpa_editing;
      let params = {
        customerId: this.accountId.toString(),
        resourceNames: [row.resource_name],
        targetCpa: isDaily ? targetVal : null,
      };
      cmpaignBudget(params).then((res) => {
        // console.log(res)
        row.target_cpa_editing = undefined;
        if (res.code == 0) {
          if (isDaily) {
            row.target_cpa = targetVal;
          } else {
            row.lifetime_budget = targetVal;
          }
        } else {
          this.$message({
            type: "warning",
            message: "失败了",
          });
        }
        this.refreshTableRow();
      });
    },
    },
}
</script>

<style lang="scss" scoped>
.ad_cell_inline_targetcp{
  text-align: left;
}
</style>